import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { useStore } from "@/core/services/store";
import { computed } from "vue";
import i18n from "@/core/plugins/vue-i18n";

export const useHelp = () => {
  const { selectedFeature } = useFeatures();
  const store = useStore();

  const handbookLink = computed(
    () =>
      getLinkForTypeAndArea("handbook", "workflows") ??
      getLinkForTypeAndArea("handbook", "general")
  );
  const videoLink = computed(
    () =>
      getLinkForTypeAndArea("video", "workflows") ??
      getLinkForTypeAndArea("video", "general")
  );

  /**
   * @param {"handbook" | "video" | "faq"} type
   * @param {string} area
   * @param {boolean} includeFeature
   */
  function getLinkForTypeAndArea(
    type,
    area = undefined,
    includeFeature = true
  ) {
    const buildPathSuffix = includeAppType =>
      [
        ...(includeAppType ? [store.getters.appType] : []),
        area,
        ...(includeFeature ? [selectedFeature.value] : []),
        type
      ]
        .filter(Boolean)
        .join(".");

    const pathSuffixWithApp = buildPathSuffix(true);
    const pathSuffixWithoutApp = buildPathSuffix(false);

    const pathWithApp = `salesChannelManagementApp.links.${pathSuffixWithApp}`;
    const pathWithoutApp = `salesChannelManagementApp.links.${pathSuffixWithoutApp}`;

    return i18n.te(pathWithApp)
      ? i18n.t(pathWithApp)
      : i18n.te(pathWithoutApp)
        ? i18n.t(pathWithoutApp)
        : null;
  }

  return { handbookLink, videoLink, getLinkForTypeAndArea };
};
