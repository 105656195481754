import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

let url = process.env.VUE_APP_API_ADMIN;

class Config extends BaseService {
  get(id) {
    return ApiService.get(url, "config_values/" + id);
  }
  getAll(params, filter = []) {
    let options = new URLSearchParams(params).toString();
    let filterParams = this.getParams(filter);
    let headers = {};
    return ApiService.get(
      url,
      "config_values?" + options + filterParams,
      headers
    );
  }
  store(data, disableAbort = false) {
    return ApiService.post(
      url + "/config_values",
      data,
      {},
      false,
      disableAbort
    );
  }
  update(id, data) {
    return ApiService.put(url + "/config_values/" + id, data);
  }
  delete(id) {
    return ApiService.delete(url + "/config_values/" + id);
  }
}
export default new Config();
