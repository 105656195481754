<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  items: {
    type: Array, // {href: string, icon: string, label: string}[]
    required: true
  }
});

const filteredItems = computed(() =>
  props.items?.filter(item => item.href?.length > 0)
);
</script>

<template>
  <v-menu v-if="filteredItems.length" content-class="bg-white" bottom offset-y>
    <template #activator="{ on, attrs }">
      <button
        v-bind="attrs"
        type="button"
        class="btn btn-icon btn-primary mr-2"
        v-on="on"
      >
        <i class="fal fa-message-question"></i>
      </button>
    </template>

    <perfect-scrollbar
      :options="{ wheelPropagation: false }"
      class="scroll h-100 max-height"
    >
      <ul class="navi navi-hover pa-0">
        <li
          v-for="item in filteredItems"
          :key="`${item.href}-${item.label}`"
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="navi-item"
        >
          <a :href="item.href" class="navi-link" target="_blank">
            <span v-if="item.icon" class="navi-icon">
              <i class="fal" :class="item.icon"></i>
            </span>
            <span v-if="item.label" class="navi-text">
              {{ item.label }}
            </span>
          </a>
        </li>
      </ul>
    </perfect-scrollbar>
  </v-menu>
</template>

<style scoped lang="scss">
.navi {
  .navi-item {
    .navi-link {
      .navi-icon {
        i {
          color: $color-connect-text;
        }
      }

      &:hover {
        .navi-icon {
          i {
            color: $color-connect-primary;
          }
        }
      }
    }
  }
}
</style>
