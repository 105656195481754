import { useStore } from "@/core/services/store";

// Get access to store
const store = useStore();

export const addEventToLoadingQueue = payload => {
  store.dispatch("loadingQueue/addEventToLoadingQueue", payload);
};
export const removeEventFromLoadingQueue = payload => {
  store.dispatch("loadingQueue/removeEventFromLoadingQueue", payload);
};
