<script setup>
import { useWorkflowStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useWorkflowStatus";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  onMounted,
  onUnmounted
} from "vue";
import ArticleProgressBar from "@/components/ExternalApps/SalesChannelManagementApp/components/ArticleProgressBar.vue";
import { useStore } from "@/core/services/store";

const props = defineProps({
  startWorkflowId: {
    type: String,
    default: ""
  },
  checkWorkflowId: {
    type: String,
    default: ""
  }
});

defineEmits(["show-dialog"]);

const {
  lastStoppedOlder,
  isFinished,
  currentWorkflowStatus,
  isMounted,
  checkForPendingWorkingJobs,
  runWorkflow,
  timeout,
  started
} = useWorkflowStatus(props.startWorkflowId, props.checkWorkflowId);

const store = useStore();

const isBusy = computed(() =>
  store.getters["loadingQueue/showLoadingBar"]("workflowStatus")
);

onMounted(() => {
  isMounted.value = true;
  checkForPendingWorkingJobs();
});

onUnmounted(() => {
  isMounted.value = false;
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
});

defineExpose({
  runWorkflow
});
</script>

<template>
  <div class="mt-3">
    <ArticleProgressBar
      v-show="!isFinished"
      :current-status="currentWorkflowStatus"
      :title="$t('salesChannelManagementApp.initialFile.building').toString()"
      :info="
        $t('salesChannelManagementApp.initialFile.buildingInfo').toString()
      "
    />
    <div v-if="!isFinished && lastStoppedOlder" class="alert alert-warning">
      {{ $t("salesChannelManagementApp.initialFile.failed") }}
    </div>
    <div v-if="started" class="alert alert-info">
      {{ $t("salesChannelManagementApp.initialFile.started") }}
    </div>
    <button
      v-if="(isFinished || lastStoppedOlder) && !isBusy"
      class="btn btn-primary mt-2"
      @click="$emit('show-dialog')"
    >
      {{ $t("salesChannelManagementApp.initialFile.generateInitialFile") }}
    </button>
  </div>
</template>

<style lang="scss" scoped></style>
