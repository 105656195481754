<template>
  <div>
    <v-progress-linear
      :active="isBusy"
      absolute
      indeterminate
      color="primary"
    />
  </div>
</template>

<script setup>
import { useStore } from "@/core/services/store";
import { onBeforeRouteUpdate } from "vue-router/composables";
import { computed, defineProps } from "vue";

// Get access to store
const store = useStore();
const props = defineProps({
  progressBarKey: {
    type: String,
    default: null
  }
});

const isBusy = computed(() =>
  store.getters["loadingQueue/showLoadingBar"](props.progressBarKey)
);

// Reset loading queues
onBeforeRouteUpdate((to, from, next) => {
  if (to.name !== from.name) {
    store.dispatch("loadingQueue/reset");
  }
  next();
});
</script>
