<script setup>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { REMOVE_ROUTE_PARAMS_BY_KEY } from "@/core/services/store/route.module";
import { computed, ref, defineProps, defineEmits, onMounted } from "vue";
import { useStore } from "@/core/services/store";
import _ from "lodash";
import i18n from "@/core/plugins/vue-i18n";

const props = defineProps({
  filterable: {
    type: Object,
    required: true
  },
  paramPrefix: {
    type: String,
    required: true
  },
  fieldBlacklist: {
    type: Array,
    default: () => ["project_id"]
  }
});
const emit = defineEmits(["execute-filter", "reset-filter"]);
const store = useStore();
const showDropdown = ref(false);
const filterDropdownAttach = ref(null);
const filterDropdownToggle = ref(null);
const form = ref(null);
const filteredFilterable = computed(() =>
  Object.fromEntries(
    Object.entries(props.filterable).filter(
      ([key]) => !props.fieldBlacklist.includes(key)
    )
  )
);
const formFields = computed(() => Object.keys(filteredFilterable.value));
const formFieldsByType = computed(() =>
  Object.entries(filteredFilterable.value).reduce((acc, [key, value]) => {
    const fieldValue = value?.type ?? value;
    if (!acc[fieldValue]) {
      acc[fieldValue] = [key];
    } else {
      acc[fieldValue].push(key);
    }
    return acc;
  }, {})
);
const operators = ref({
  text: ["equals", "notEquals", "in", "notIn"],
  number: ["equals", "notEquals", "in", "notIn", "lesserThan", "greaterThan"],
  date: ["equals", "notEquals", "lesserThan", "greaterThan"],
  datetime: ["lesserThan", "greaterThan"],
  select: ["equals", "notEquals"],
  multiselect: ["in", "notIn"],
  bool: ["equals"],
  static: []
});

const formDefinition = computed(() => {
  const fields = ref([
    {
      type: "select",
      name: "field",
      options: formFields.value,
      default: formFields.value.at(0),
      disableValidations: true
    }
  ]);

  for (const type of _.uniq(Object.values(filteredFilterable.value))) {
    let fieldType = type?.type ?? type;
    const dependsOn = [
      {
        name: "field",
        values: formFieldsByType.value[fieldType]
      }
    ];

    if (operators.value[fieldType]) {
      fields.value.push({
        type: "select",
        name: "op",
        options: operators.value[fieldType],
        default: operators.value[fieldType].at(0),
        dependsOn: dependsOn,
        disabled: !operators.value[fieldType].length,
        disableValidations: true
      });
    }

    let field = {
      type: fieldType,
      name: "value",
      dependsOn: dependsOn,
      default: "",
      disabled: fieldType === "static",
      disableValidations: true
    };

    if (fieldType === "select" || fieldType === "multiselect") {
      field = { ...field, ...{ options: type.options } };
    } else if (fieldType === "bool") {
      field = { ...field, ...{ type: "select", options: ["true", "false"] } };
    }

    fields.value.push(field);
  }

  const definition = ref([
    {
      label: "Filter",
      type: "group",
      name: "filter",
      compactLayout: true,
      fields: fields.value,
      validations: {
        minLength: 1
      },
      default: [
        {
          field: null
        }
      ],
      addGroupLabel: i18n.t("tableHelper.addFilter"),
      removeGroupLabel: i18n.t("tableHelper.removeFilter")
    }
  ]);

  return definition.value;
});
const formConfig = ref({
  snippetPrefix: "tableHelper",
  showLabels: false,
  fullWidth: true,
  labelStacked: true
});
const filters = ref([]);
const activeFilters = computed(() => {
  const formFilterLength = form?.value?.filter?.length;
  const filterLength = filters.value?.length;

  if (formFilterLength > filterLength) return 0;

  return formFilterLength;
});

function executeFilter() {
  filters.value = [];

  store.dispatch("route/" + REMOVE_ROUTE_PARAMS_BY_KEY, {
    prefix: props.paramPrefix,
    key: "filter"
  });

  for (const filter of form.value.filter) {
    if (!Object.keys(filter).length) continue;

    filters.value.push({
      key: filter.field,
      op: filter.op ?? "equals",
      value: filter.value
    });
  }

  closeFilterDropdown();

  emit("execute-filter", filters.value);
}

function resetFilter() {
  filters.value = [];

  if (form.value) {
    form.value.filter = [{}];
  }

  emit("reset-filter");
}

function closeFilterDropdown() {
  setTimeout(() => {
    showDropdown.value = false;
  }, 20);
}

onMounted(() => {
  let params = store.getters["route/params"](props.paramPrefix);
  // js recognizes "filter" as the Array prototype filter method, if the object key doesn't exist
  // therefore we have to make sure that "filter" is not a function
  let existingFilter =
    params?.["filter"] && typeof params?.["filter"] !== "function"
      ? params["filter"]
      : [];

  let normalizedFilter = ref(
    existingFilter.map(entry => {
      return {
        field: entry.key,
        op: entry.op,
        value: entry.value
      };
    })
  );

  if (!filters.value.length) {
    filters.value = existingFilter;
  }

  if (!form.value) {
    form.value = {};
  }

  form.value.filter = normalizedFilter.value;
});
</script>

<template>
  <div class="table-helper-filter">
    <div>
      <button ref="filterDropdownToggle" class="btn btn-light btn-toggle mb-1">
        <i class="fal fa-filter"></i>
        <span>{{ $t("tableHelper.filter") }}</span>

        <span v-if="activeFilters" class="btn-toggle-badge">
          {{ activeFilters }}
        </span>
      </button>

      <button
        v-if="activeFilters"
        v-b-popover.hover.top="$t('tableHelper.filterReset')"
        class="btn btn-light btn-toggle ml-5 mb-1"
        @click="resetFilter"
      >
        <i class="fal fa-filter-slash p-0"></i>
      </button>

      <v-menu
        v-model="showDropdown"
        :activator="filterDropdownToggle"
        :attach="filterDropdownAttach"
        :close-on-content-click="false"
        :max-width="700"
        :nudge-bottom="5"
        content-class="table-helper-filter-content"
      >
        <div class="table-helper-filter-dropdown-inner min-w-md-700px">
          <FormHelper
            key="filterForm"
            v-model="form"
            class="w-100"
            :form="formDefinition"
            :config="formConfig"
          />

          <div class="text-right">
            <button class="btn btn-primary" @click="executeFilter">
              {{ $t("tableHelper.applyFilter") }}
            </button>
          </div>
        </div>
      </v-menu>
    </div>
    <div ref="filterDropdownAttach" class="menu-attach position-relative"></div>
  </div>
</template>

<style scoped lang="scss">
.table-helper-filter {
  position: relative;

  .btn-toggle {
    color: $color-connect-text;
    position: relative;

    i {
      color: $color-connect-text;
    }

    &-badge {
      position: absolute;
      top: -7px;
      right: -7px;
      color: #fff;
      background-color: $color-connect-primary;
      border-radius: 50%;
      aspect-ratio: 1;
      width: 20px;
    }
  }

  &-content {
    overflow: visible;
    contain: none;
  }

  &-dropdown {
    position: absolute;
    top: 100%;
    left: 20px;
    z-index: 200;

    &-inner {
      background-color: #fff;
      padding: 20px;
      box-shadow: #282d360d 0 8px 20px;
      border-radius: 6px;

      :deep(.compact-group) {
        background: none;

        > div {
          &:nth-last-child(2) {
            padding-bottom: 20px;
            border-bottom: 1px solid $color-connect-text;
          }
        }
      }

      :deep(.compact) {
        gap: 10px;

        .form-helper {
          > .row {
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
          }

          .v-picker {
            max-width: none;
          }
        }
      }
    }
  }
}
</style>
