import { computed, ref, watch } from "vue";
import { Error } from "@/core/plugins/swal";
import ProcessManager from "@/components/Workflows/processManager";
import ProcessIteration from "@/components/Workflows/processIteration";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";

export const useWorkflowStatus = (startWorkflowId, checkWorkflowId) => {
  const lastStoppedIteration = ref({});
  const isMounted = ref(false);
  const timeout = ref();
  const started = ref(false);

  const currentWorkflowStatus = computed(() => {
    return lastStoppedIteration.value?.case_identifier ?? "0 / 0";
  });

  const lastStoppedOlder = computed(() => {
    if (!lastStoppedIteration.value?.finished_at) {
      return false;
    }
    const date = new Date(lastStoppedIteration.value?.finished_at);
    const now = new Date();
    const differenceInMilliseconds = now - date;
    const threeHoursInMilliseconds = 60 * 60 * 1000;

    return differenceInMilliseconds > threeHoursInMilliseconds;
  });

  const isFinished = computed(() => {
    const parts = currentWorkflowStatus.value.toString().split("/");
    if (parts.length !== 2) {
      return true;
    }
    const numerator = parseFloat(parts[0].trim());
    const denominator = parseFloat(parts[1].trim());

    return numerator === denominator;
  });

  const { selectedIntegration } = useIntegration();
  const { selectedFeature } = useFeatures();

  watch(
    () => isFinished.value,
    value => {
      if (value || lastStoppedOlder.value) {
        return;
      }
      started.value = false;
    }
  );

  watch(
    () => lastStoppedOlder.value,
    value => {
      if (value || isFinished.value) {
        return;
      }
      started.value = false;
    }
  );

  async function runWorkflow(params = {}) {
    addEventToLoadingQueue({
      key: "workflowStatus"
    });
    try {
      await ProcessManager.run(startWorkflowId, {
        params: params
      });
      started.value = true;
    } catch (error) {
      removeEventFromLoadingQueue({
        key: "workflowStatus"
      });

      Error(error);
    }
  }

  async function checkForPendingWorkingJobs() {
    removeEventFromLoadingQueue({
      key: "workflowStatus"
    });

    await getLastStoppedJob();
    if (isFinished.value && lastStoppedIteration.value) {
      if (lastStoppedIteration.value?.return?.initialImportFile) {
        selectedIntegration.value[
          selectedFeature.value
        ].initialImportFile.value =
          lastStoppedIteration.value.return?.initialImportFile;
      }

      lastStoppedIteration.value = {};
    }

    // Recursively call the function every 5s until there are none pending/working job
    if (isMounted.value) {
      timeout.value = setTimeout(checkForPendingWorkingJobs, 5000);
    }
  }

  async function getLastStoppedJob() {
    let params = {
      page: 1,
      perPage: 1,
      filter: [
        {
          key: "process_id",
          op: "equals",
          value: checkWorkflowId
        },
        {
          key: "status",
          op: "equals",
          value: "process.stopped"
        }
      ]
    };

    lastStoppedIteration.value = await getIteration(params);
  }

  async function getIteration(params) {
    let iteration = {};
    await ProcessIteration.getAll(params)
      .then(response => {
        iteration = response.data?.[0];
      })
      .catch(error => {
        Error(error);
      });
    return iteration;
  }

  return {
    isFinished,
    lastStoppedOlder,
    checkForPendingWorkingJobs,
    runWorkflow,
    currentWorkflowStatus,
    isMounted,
    lastStoppedIteration,
    timeout,
    started
  };
};
