import ApiService from "@/core/services/api.service";

let url = process.env.VUE_APP_API_ADMIN;

class ProcessManager {
  get(id, flat = false, optionalHeaders = {}, disableAbort = false) {
    let params = "";
    if (flat) {
      params = "?flat=true";
    }
    return ApiService.get(
      url,
      "processes/" + id + params,
      optionalHeaders,
      disableAbort
    );
  }

  getAll(params, flat = false) {
    let options = "?";
    if (params.page) {
      options += "&page=" + params.page;
    }
    if (params.perPage) {
      options += "&perPage=" + params.perPage;
    }
    if (params.searchTerm) {
      options += "&searchTerm=" + params.searchTerm;
    }
    if (flat) {
      options += "&flat=1";
    }
    if (params.project_id !== undefined) {
      options += "&project_id=" + params.project_id;
    }
    if (params.no_project) {
      options += "&no_project=1";
    }
    if (params.for_client) {
      options += "&for_client=1";
    }

    if (params.projects) {
      let index = 0;
      params.projects.forEach(project => {
        options += "&project_id[" + index++ + "]=" + project;
      });
    }
    let headers = {};
    return ApiService.get(url, "processes" + options, headers);
  }
  store(data) {
    return ApiService.post(url + "/processes", data);
  }
  update(processId, data) {
    return ApiService.put(url + "/processes/" + processId, data);
  }
  delete(id) {
    return ApiService.delete(url + "/processes/" + id);
  }
  run(id, data = {}, optionalHeaders = {}) {
    return ApiService.post(
      url + "/processes/" + id + "/run",
      data,
      optionalHeaders
    );
  }
  test(id, data) {
    return ApiService.post(url + "/processes/" + id + "/test", data);
  }
  abort(id) {
    return ApiService.post(url + "/processes/" + id + "/abort");
  }
  getFrequencies(params) {
    let options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "frequencies" + options);
  }
}
export default new ProcessManager();
