<template>
  <div class="pagination mt-10">
    <div class="row justify-space-between align-center w-100">
      <div v-if="!disablePageSize" class="col-6">
        <FormHelper
          key="pageSizeSelectionForm"
          v-model="pageSizeSelectionForm"
          class="w-auto"
          :form="pageSizeSelectionFormDefinition"
          :config="pageSizeSelectionFormConfig"
          @input="changePageSize"
        />
      </div>

      <div v-if="!disablePageSelection" class="col-6">
        <div class="page-selection-grid align-center">
          <div class="column-page-size">
            <FormHelper
              key="pageSelectionForm"
              v-model="pageSelectionForm"
              class="w-100"
              :form="pageSelectionFormDefinition"
              :config="pageSelectionFormConfig"
              @input="changeCurrentPage"
            />
          </div>

          <div>
            <button class="btn btn-small btn-icon" @click="goToPreviousPage">
              <i class="fal fa-arrow-left"></i>
            </button>
            <button class="btn btn-small btn-icon" @click="goToNextPage">
              <i class="fal fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  defineEmits,
  defineProps,
  getCurrentInstance,
  ref
} from "vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { useParams } from "@/composables/TableHelper/useParams";

const emit = defineEmits(["change-page", "change-page-size"]);

const props = defineProps({
  disablePageSize: {
    type: Boolean,
    default: false
  },
  disablePageSelection: {
    type: Boolean,
    default: false
  },
  from: {
    type: Number,
    default: 1
  },
  to: {
    type: Number,
    default: 1
  },
  perPage: {
    type: Number,
    required: true
  },
  totalPages: {
    type: Number,
    required: true
  },
  totalRecords: {
    type: Number,
    required: true
  },
  paramPrefix: {
    type: [String, Number],
    default: ""
  }
});

const prefix = computed(() => props.paramPrefix);
const { page, perPage } = useParams(prefix);
const pageSizes = ref([15, 25, 50, 100]);
const i18n = getCurrentInstance().proxy.$i18n;

const currentActivePage = computed(() => {
  return parseInt(page.value ?? 1);
});

const currentActivePageSize = computed(() =>
  parseInt(perPage.value ?? pageSizes.value[0])
);

const totalPages = computed(() => props.totalPages);

const pages = computed(() =>
  Array.from({ length: totalPages.value }, (_, index) => index + 1)
);

const pageSelectionForm = ref({
  activePage: page.value ?? 1
});
const pageSelectionFormAppend = computed(() =>
  i18n.t("pagination.pageOf", {
    page: totalPages.value
  })
);
const pageSelectionFormDefinition = ref([
  {
    type: "select",
    name: "activePage",
    default: currentActivePage.value,
    class: "page-selection",
    options: pages,
    prepend: "page",
    append: pageSelectionFormAppend,
    prependVariant: "minimal",
    appendVariant: "minimal",
    disableValidations: true
  }
]);
const pageSelectionFormConfig = ref({
  snippetPrefix: "pagination",
  showLabels: false,
  fullWidth: true,
  labelStacked: true,
  disableSkeletonLoading: true
});

const pageSizeSelectionForm = ref({
  activePageSize: perPage.value ?? 15
});
const pageSizeSelectionFormAppend = computed(() =>
  i18n.t("pagination.entriesPerPage", {
    current: props.from + " - " + props.to,
    total: props.totalRecords
  })
);
const pageSizeSelectionFormDefinition = ref([
  {
    type: "select",
    name: "activePageSize",
    default: currentActivePageSize.value,
    options: pageSizes,
    prepend: "entriesPrepend",
    append: pageSizeSelectionFormAppend,
    prependVariant: "minimal",
    appendVariant: "minimal",
    disableValidations: true
  }
]);
const pageSizeSelectionFormConfig = ref({
  snippetPrefix: "pagination",
  showLabels: false,
  fullWidth: true,
  labelStacked: true,
  disableSkeletonLoading: true
});

function changeCurrentPage() {
  if (pageSelectionForm.value.activePage === currentActivePage.value) return;
  emit("change-page", pageSelectionForm.value.activePage);
}

function goToPreviousPage() {
  if (currentActivePage.value <= 1) return;

  changePage(currentActivePage.value - 1);
}

function goToNextPage() {
  if (currentActivePage.value >= totalPages.value) return;

  changePage(currentActivePage.value + 1);
}

function changePage(newPage) {
  if (pageSelectionForm.value.activePage === newPage) return;
  pageSelectionForm.value.activePage = newPage;
  emit("change-page", pageSelectionForm.value.activePage);
}

function changePageSize() {
  if (
    pageSizeSelectionForm.value.activePageSize === currentActivePageSize.value
  )
    return;

  pageSelectionForm.value.activePage = 1;
  emit("change-page-size", pageSizeSelectionForm.value.activePageSize);
}
</script>

<style lang="scss" scoped>
$page-selection-column-gap: 20px;

.pagination {
  :deep(.form-control) {
    background-color: $color-connect-background;
    margin-top: 0;
    max-width: min-content;

    &.v-autocomplete {
      &.v-select {
        input {
          min-width: 30px;
        }
      }
    }
  }

  :deep(.page-selection) {
    .input-group {
      justify-content: flex-end;
    }
  }

  .column-page-size {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -$page-selection-column-gap / 2;
      transform: translateY(-50%);
      border-right: 1px solid $color-connect-primary-light;
      height: 80%;
    }
  }

  .btn-icon {
    > i {
      color: $color-connect-text;

      &:hover {
        color: $color-connect-primary;
      }
    }
  }

  .page-selection-grid {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: $page-selection-column-gap;
  }
}
</style>
