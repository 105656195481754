import { render, staticRenderFns } from "./WorkflowStatus.vue?vue&type=template&id=2e663e0d&scoped=true"
import script from "./WorkflowStatus.vue?vue&type=script&setup=true&lang=js"
export * from "./WorkflowStatus.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e663e0d",
  null
  
)

export default component.exports