import { computed, ref } from "vue";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import _ from "lodash";
import { useStore } from "@/core/services/store";
import { fieldDefaults } from "@/components/Tools/FormHelper/Helper/constants";

const getActivatedFeatures = features => {
  return features
    ? Object.keys(features).filter(feature => features[feature])
    : [];
};

const selectedFeature = ref();
const featuresWithChangedActiveState = ref({
  product: false,
  salesOrder: false,
  stock: false,
  prices: false,
  category: false,
  tracking: false
});
const activationDatesToSet = computed(() =>
  Object.keys(featuresWithChangedActiveState.value || {}).filter(
    feature => featuresWithChangedActiveState.value[feature]
  )
);
const store = useStore();
const { selectedIntegration, formValueChange } = useIntegration();

export const useFeatures = () => {
  const features = computed(() =>
    getActivatedFeatures(selectedIntegration.value?.features?.value)
  );

  /**
   *
   * @param field
   */
  function onFeaturesChanged(field) {
    if (!field.value) {
      return;
    }
    const featureKey = field.name;

    if (!selectedIntegration.value?.[featureKey]) {
      selectedIntegration.value[featureKey] = {};
    }

    const areas = store.getters.form("app." + featureKey);
    Object.keys(areas).forEach(area => {
      const form = store.getters.form("app." + featureKey + "." + area);

      const name = featureKey + _.upperFirst(area);
      let presetValue = {
        name: name,
        label: name,
        project_id: selectedIntegration.value.id,
        type: "json",
        value: {}
      };

      if (!selectedIntegration.value?.[featureKey]?.[area]) {
        form.forEach(field => {
          presetValue.value[field.name] =
            field.default ?? fieldDefaults[field.type];
        });

        selectedIntegration.value[featureKey][area] = ref(presetValue).value;
      }

      form.forEach(field => {
        const payload = {
          name: field.name,
          value: presetValue.value[field.name]
        };

        if (selectedIntegration.value?.[featureKey]?.[area]?.id) return;

        formValueChange(payload, featureKey, area);
      });
    });
  }

  return {
    features,
    selectedFeature,
    activationDatesToSet,
    featuresWithChangedActiveState,
    onFeaturesChanged
  };
};
