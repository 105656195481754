import { onUnmounted, ref, watchEffect } from "vue";

export const useApplyOrderBy = orderBy => {
  const order = ref({});

  const unwatch = watchEffect(() => {
    const { field, direction } = orderBy.value;

    order.value =
      field && direction
        ? { ["orderBy[" + orderBy.value.field + "]"]: direction }
        : {};
  });

  onUnmounted(() => unwatch());

  return { order };
};
