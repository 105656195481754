import { useStore } from "@/core/services/store";
import { completeStartedStates } from "@/components/ExternalApps/SalesChannelManagementApp/utility/constants";

export function getStagesPayload(
  paramPrefix,
  defaultStates,
  additionalCondition = {},
  useConditions = true
) {
  const store = useStore();
  const params = store.getters["route/params"](paramPrefix) || {};
  const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? "GMT";
  const orderBy = Object.entries(params)
    .map(([key, value]) => ({
      field: key.startsWith("orderBy")
        ? key.replace(/^orderBy\[|]$/g, "")
        : undefined,
      direction: value === "asc" ? 1 : -1
    }))
    .find(order => order.field !== undefined);

  let parameters = [
    {
      name: "timezone",
      type: "text",
      value: timeZone
    },
    {
      name: "orderByField",
      type: "text",
      value: orderBy?.field ?? "lastIteration"
    },
    {
      name: "orderByDirection",
      type: "number",
      value: orderBy?.direction ?? -1
    }
  ];

  if (useConditions) {
    const conditions = buildCondition(
      params,
      defaultStates,
      additionalCondition
    );

    if (conditions.length) {
      parameters.push({
        name: "condition",
        type: "condition",
        value: {
          type: "group",
          operator: "and",
          children: conditions
        }
      });
    }
  }

  return {
    limit: params?.perPage ?? 15,
    page: params?.page ?? 1,
    parameters: parameters
  };
}

/**
 * @param {object} params
 * @param {{value: {label: string, value: string}[]}} defaultStates
 * @param {object} additionalCondition
 * @returns {[{valid: boolean, field: string, type: string, value, operator: string}]}
 */
function buildCondition(params, defaultStates, additionalCondition) {
  // js recognizes "filter" as the Array prototype filter method, if the object key doesn't exist
  // therefore we have to make sure that "filter" is not a function
  let reportingFilter =
    params?.["filter"] && typeof params?.["filter"] !== "function"
      ? params?.["filter"]
      : [];
  let conditions = [
    {
      type: "condition",
      field: "$state",
      operator: "$in",
      value: defaultStates.value,
      valid: true
    }
  ];
  if (Object.keys(additionalCondition).length) {
    conditions.push(additionalCondition);
  }
  let operators = {
    in: "$in",
    notIn: "$nin",
    greaterThan: "$gt",
    lesserThan: "$lt",
    equals: "$eq",
    notEquals: "$ne"
  };
  for (const filterItem of reportingFilter) {
    if (filterItem.key === "hasError") {
      conditions.push({
        type: "condition",
        field: "$state",
        operator: filterItem.value ? "$in" : "$nin",
        value: completeStartedStates,
        valid: true
      });

      continue;
    }

    conditions.push({
      type: "condition",
      field: "$" + filterItem.key,
      operator: operators[filterItem["op"]],
      value: filterItem.value,
      valid: true
    });
  }

  return conditions;
}

export function transformParameters(parameters) {
  const params = {};
  parameters.forEach(item => {
    params[item.name] = item.value;
  });
  return params;
}
