<script setup>
import ProductSelectionForms from "@/components/ExternalApps/SalesChannelManagementApp/components/Forms/ProductSelectionForms.vue";
import SkeletonLoading from "@/components/ExternalApps/SalesChannelManagementApp/components/Forms/SkeletonLoading.vue";
import { useAppStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppStatus";

const { isAppInitialized } = useAppStatus();
</script>

<template>
  <div>
    <SkeletonLoading v-if="!isAppInitialized" />
    <ProductSelectionForms v-else />
  </div>
</template>

<style lang="scss" scoped></style>
