import { computed, ref } from "vue";
import i18n from "@/core/plugins/vue-i18n";
import { useStore } from "@/core/services/store";
import { useArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArea";
import DataSets from "@/components/DataSets/dataSets";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { stateMachineStates } from "@/components/ExternalApps/SalesChannelManagementApp/utility/stateMachineStates";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { getStagesPayload } from "@/components/ExternalApps/SalesChannelManagementApp/utility/reporting";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useMigration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigration";

const store = useStore();
const { selectedArea } = useArea();
const { selectedFeature } = useFeatures();
const { selectedIntegration } = useIntegration();
const fields = [
  {
    key: "productNumber",
    label: i18n.t("salesChannelManagementApp.reporting.productNumber"),
    thClass: "pl-5",
    tdClass: "pl-5",
    sortable: true
  },
  {
    key: "productId",
    label: i18n.t("salesChannelManagementApp.reporting.productId"),
    sortable: true
  },
  {
    key: "source",
    label: i18n.t("salesChannelManagementApp.reporting.source"),
    sortable: true
  },
  {
    key: "destination",
    label: i18n.t("salesChannelManagementApp.reporting.destination"),
    sortable: true
  },
  {
    key: "state",
    label: i18n.t("salesChannelManagementApp.reporting.state"),
    sortable: true,
    type: "state",
    entity: "product"
  },
  {
    key: "lastIteration",
    label: i18n.t("salesChannelManagementApp.reporting.lastIteration"),
    sortable: true
  }
];
const filterable = computed(() => {
  return {
    state: {
      type: "select",
      options: stateMachineStates[selectedFeature.value][selectedArea.value]
    },
    updated_at: "datetime",
    hasError: "checkbox"
  };
});
const defaultStates = computed(() =>
  stateMachineStates[selectedFeature.value][selectedArea.value].map(
    entry => entry.value
  )
);

export const useMigrationReporting = () => {
  const { getWorkflowPendingStatus, migrationStepFinished } = useMigration();

  const data = ref({
    export: [],
    import: []
  });
  const meta = ref({
    export: {},
    import: {}
  });
  const paramPrefix = computed(
    () => "migration-reporting-" + selectedArea.value
  );
  const migrationDatasetId = computed(
    () => store.getters.migrationDatasetId() ?? ""
  );

  async function executeDataSet() {
    addEventToLoadingQueue({
      key: "migration-reporting",
      group: "migration-reporting"
    });

    try {
      const condition = {
        type: "group",
        operator: "and",
        children: [
          {
            type: "condition",
            field: "$dataEntity",
            operator: "$in",
            value: ["product", "variant"],
            valid: true
          },
          {
            type: "condition",
            field: "$channelSpecificName",
            operator: "$in",
            value: [
              "Xentral",
              selectedIntegration?.value?.name
                ?.replace(store.getters.selectedApp.name, "")
                .trim() ?? ""
            ],
            valid: true
          }
        ]
      };

      const payload = getStagesPayload(
        paramPrefix.value,
        defaultStates,
        condition
      );

      const stagesResponse = await DataSets.execute(
        migrationDatasetId.value,
        payload
      );
      data.value[selectedArea.value] = stagesResponse.data;
      meta.value[selectedArea.value] = stagesResponse.meta;

      const isPending = await getWorkflowPendingStatus(selectedArea.value);
      migrationStepFinished.value[selectedArea.value] = isPending ?? false;
    } catch (error) {
      Error(error);
    } finally {
      removeEventFromLoadingQueue({ group: "migration-reporting" });
    }
  }

  return {
    data,
    fields,
    meta,
    filterable,
    paramPrefix,
    executeDataSet,
    getWorkflowPendingStatus
  };
};
