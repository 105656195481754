import { useStore } from "@/core/services/store";
import { computed } from "vue";

const store = useStore();

export const useUtility = () => {
  const xentralProjectHeader = computed(() => {
    const xentralProjectId = store.getters?.xentralPresetProjectId;

    if (!xentralProjectId) {
      return {};
    }

    return {
      "x-project-id": store.getters.xentralPresetProjectId
    };
  });

  return { xentralProjectHeader };
};
