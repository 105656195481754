import { ref } from "vue";
import i18n from "@/core/plugins/vue-i18n";

const debugData = ref([]);
const meta = ref({});
const fields = ref([
  {
    key: "id",
    label: i18n.t("salesChannelManagementApp.productSelectionTable.id"),
    thStyle: { width: "350px" },
    thClass: "pl-5",
    tdClass: "pl-5"
  },
  {
    key: "name",
    label: i18n.t("salesChannelManagementApp.productSelectionTable.name")
  },
  {
    key: "productNumber",
    label: i18n.t(
      "salesChannelManagementApp.productSelectionTable.productNumber"
    )
  },
  {
    key: "projectId",
    label: i18n.t("salesChannelManagementApp.productSelectionTable.projectId")
  },
  {
    key: "merchandiseGroupId",
    label: i18n.t(
      "salesChannelManagementApp.productSelectionTable.merchandiseGroupId"
    )
  },
  {
    key: "ean",
    label: i18n.t("salesChannelManagementApp.productSelectionTable.ean")
  },
  {
    key: "isDisabled",
    label: i18n.t("salesChannelManagementApp.productSelectionTable.isDisabled")
  }
]);

export const useProductSelection = () => {
  function hasValidEntry(entry) {
    if (entry?.valid === false) {
      return false;
    }

    if (entry?.children) {
      for (const child of entry.children) {
        if (!hasValidEntry(child)) {
          return false;
        }
      }
    } else {
      return !(!entry?.field || !entry?.operator);
    }

    return true;
  }

  return {
    debugData,
    hasValidEntry,
    meta,
    fields
  };
};
