import { ref } from "vue";
import { useStore } from "@/core/services/store";

const selectedArea = ref();
const store = useStore();

// Skip feature Areas
const featureAreasToSkip = ["baseSettings", "settings"];

export const useArea = () => {
  /**
   * get all Areas of a given feature from the current app
   * @param featureName
   * @returns {*|*[]}
   */
  function getAreas(featureName) {
    let areas = store.getters.form("app." + featureName);
    if (areas === undefined || areas === null) {
      return [];
    }
    areas = Object.keys(areas);
    // Filter out Areas that are in the featureAreasToSkip array
    return areas.filter(area => !featureAreasToSkip.includes(area));
  }

  return { getAreas, selectedArea };
};
