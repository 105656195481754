const FIELD_PREFIX = "$";

export const productSelectionVariables = [
  {
    text: "Artikel ID",
    field: FIELD_PREFIX + "masterData.id",
    name: "id"
  },
  {
    text: "Artikelkategorie ID",
    field: FIELD_PREFIX + "masterData.merchandiseGroupId",
    name: "merchandiseGroup"
  },
  {
    text: "Artikel (DE)",
    field: FIELD_PREFIX + "masterData.name",
    name: "name"
  },
  {
    text: "Artikel Nr.",
    field: FIELD_PREFIX + "masterData.productNumber",
    name: "number"
  },
  {
    text: "Projekt ID",
    field: FIELD_PREFIX + "masterData.projectId",
    name: "project"
  },
  {
    text: "Projekt Name",
    field: FIELD_PREFIX + "masterData.projectName",
    name: "projectName"
  },
  {
    text: "EAN Nr. / Barcode",
    field: FIELD_PREFIX + "masterData.ean",
    name: "ean"
  },
  {
    text: "Freifeld-Nr.",
    field: FIELD_PREFIX + "customFields.id",
    name: "freeFieldNumber",
    type: "freeField"
  },
  {
    text: "Freifeldwert",
    field: FIELD_PREFIX + "customFields.value",
    name: "freeFieldValue",
    type: "freeField"
  },
  {
    text: "Sperre aktiv",
    field: FIELD_PREFIX + "masterData.isDisabled",
    name: {
      false: "onlyActive",
      true: "onlyLocked"
    }
  },
  {
    text: "Online-Shop UUID",
    name: "salesChannel"
  },
  {
    text: "Hersteller",
    field: FIELD_PREFIX + "manufacturer.name",
    name: "manufacturerName"
  },
  {
    text: "Hersteller Nr.",
    field: FIELD_PREFIX + "manufacturer.number",
    name: "manufacturerNumber"
  }
];
