import { computed, ref } from "vue";
import { useStore } from "@/core/services/store";
import i18n from "@/core/plugins/vue-i18n";
import { Confirmation, Error } from "@/core/plugins/swal";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { useFeatureArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatureArea";

const store = useStore();
const { formValueChange } = useIntegration();
const { selectedFeature, features, featuresWithChangedActiveState } =
  useFeatures();
const { disableDependingOnFeatureStatus } = useFeatureArea();
const formHelper = ref([]);

export const useAppHeader = () => {
  // Live-/Devmode in features area
  const featureBaseForm = computed(() => {
    if (selectedFeature.value === null) {
      return [];
    }

    return store.getters.form("app." + selectedFeature.value + ".baseSettings");
  });

  const activeIntegration = computed(() => {
    return store.getters.selectedIntegration;
  });

  /**
   * Function to trigger confirmation when changing the status of an integration
   * @param baseSetting
   * @param event
   * @param selectedFeature
   * @param area
   */
  function triggerConfirmation(baseSetting, event, selectedFeature, area) {
    let params = {
      title: i18n.t("salesChannelManagementApp.changeActiveStatusTitle"),
      text: baseSetting.value.active
        ? i18n.t("salesChannelManagementApp.changeActiveStatusTextInactive")
        : i18n.t("salesChannelManagementApp.changeActiveStatusTextActive"),
      cancelButtonText: i18n.t("general.cancel"),
      confirmButtonText: i18n.t("general.confirm")
    };

    Confirmation(params).then(result => {
      if (!result.isConfirmed) {
        return (baseSetting.value.active = !baseSetting.value.active);
      }
      // Validate mandatory fields if feature changes to live mode
      if (event.value) {
        if (!validateForms()) {
          Error(i18n.t("salesChannelManagementApp.livemodeError"));
          return (baseSetting.value.active = false);
        }
      }

      disableDependingOnFeatureStatus();

      formValueChange(event, selectedFeature, area, features.value);

      featuresWithChangedActiveState.value[selectedFeature] = event.value;
    });
  }

  function validateForms() {
    if (!formHelper.value) return true;

    let counter = 0;
    for (const form of formHelper.value) {
      if (form?.validate() !== true) {
        counter++;
      }
    }
    return counter === 0;
  }

  return {
    featureBaseForm,
    activeIntegration,
    triggerConfirmation,
    formHelper,
    validateForms
  };
};
