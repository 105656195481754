<script setup>
import Card from "@/components/Tools/Card/Card.vue";
import Header from "@/components/ExternalApps/SalesChannelManagementApp/components/Header.vue";
import Migration from "@/components/ExternalApps/SalesChannelManagementApp/components/Areas/Migration.vue";
import Progressbar from "@/components/Tools/Progressbar.vue";
import Settings from "@/components/ExternalApps/SalesChannelManagementApp/components/Areas/Settings.vue";
import Workflows from "@/components/ExternalApps/SalesChannelManagementApp/components/Areas/Workflows.vue";
import ProductSelection from "@/components/ExternalApps/SalesChannelManagementApp/components/Areas/ProductSelection.vue";
import ArticleImportStatus from "@/components/ExternalApps/SalesChannelManagementApp/components/ArticleImportStatus.vue";
import { useArticleImport } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArticleImport";
import { useAppStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppStatus";
import StoppedProcessIterationTable from "@/components/ExternalApps/SalesChannelManagementApp/components/Areas/StoppedProcessIterationTable.vue";

const { articleImportPending } = useArticleImport();
const { isAppInitialized } = useAppStatus();
</script>

<template>
  <div>
    <Progressbar />
    <div class="pt-3">
      <!--    START: HEADER    -->
      <Header
        :is-detail-page="true"
        :show-integration-options="$route.params.area === 'workflows'"
      />
      <!--    END: HEADER    -->
      <Card
        v-if="
          ($route.params.area === 'productSelection' ||
            $route.params.area === 'journal') &&
          isAppInitialized
        "
        v-show="articleImportPending"
        class="my-6 pa-6 border-primary"
      >
        <ArticleImportStatus />
      </Card>
      <Card class="p-6">
        <!--    START: CONTENTS    -->
        <Migration v-if="$route.params.area === 'migration'" />
        <Workflows v-else-if="$route.params.area === 'workflows'" />
        <Settings v-else-if="$route.params.area === 'settings'" />
        <ProductSelection
          v-else-if="$route.params.area === 'productSelection'"
          :class="{ overlay: articleImportPending }"
        />
        <!--    END: CONTENTS    -->
      </Card>
      <StoppedProcessIterationTable
        v-if="$route.params.area === 'productSelection' && isAppInitialized"
        class="mt-4"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.overlay) {
  opacity: 0.5;
  pointer-events: none;
}

:deep(.border-primary) {
  border: 1px solid $color-connect-primary;
}
</style>
