<script setup>
import { useArticleImport } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArticleImport";

const {
  runCFLWorkflow,
  showArticleImportModal,
  xentralArticleStock,
  showArticleImportPrepareModal
} = useArticleImport();
</script>

<template>
  <div class="text-center">
    <div class="">
      <div class="font-weight-bold mb-2">
        {{
          $t("salesChannelManagementApp.journal.articleFound", {
            stock: xentralArticleStock
          })
        }}
      </div>
      <div>
        {{ $t("salesChannelManagementApp.journal.articleListExportInfo") }}
      </div>
      <div class="d-flex mt-8">
        <button
          class="btn btn-outline-secondary w-50"
          @click="
            showArticleImportModal = false;
            showArticleImportPrepareModal = false;
          "
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary w-50 ml-2" @click="runCFLWorkflow">
          {{ $t("salesChannelManagementApp.journal.createArticleList") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
