import { ref } from "vue";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { useStore } from "@/core/services/store";
import Assignments from "@/components/Assignments/assignments";
import _ from "lodash";
import { useFeatureArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatureArea";

const assignmentTables = ref({});

export const useAssignmentTables = () => {
  const { selectedIntegration } = useIntegration();
  const { selectedFeature } = useFeatures();
  const { featureAreas } = useFeatureArea();
  const store = useStore();

  /**
   * exchange the assignment table id with the corresponding values
   * @param field
   * @param value
   */
  function resolveAssignmentTable(field, value) {
    let id = Array.isArray(value) ? value[0] : value;

    if (id && typeof id === "string") {
      let currentAssignment = store.getters.assignment(id);

      for (const area of featureAreas.value) {
        if (
          selectedIntegration.value[selectedFeature.value]?.[area]?.value?.[
            field.name
          ]
        ) {
          selectedIntegration.value[selectedFeature.value][area].value[
            field.name
          ] = Object.values(currentAssignment.values);
        }
      }

      assignmentTables.value[currentAssignment.name] = currentAssignment;
    }
  }

  /**
   * Take the values of the group form element and either save them to an existing assignment table
   * or create a new assignment table with these values
   * @param field
   * @param value
   * @param fieldPath
   * @returns {Promise<*>}
   */
  async function saveAssignmentTable(field, value, fieldPath) {
    let returnValue = value ?? {};

    if (!returnValue) return returnValue;

    const assignmentTableName = `${selectedIntegration.value.name}.${fieldPath}.${field.name}`;
    let currentAssignmentTable = assignmentTables.value[assignmentTableName];

    if (!currentAssignmentTable && !Array.isArray(value)) {
      currentAssignmentTable = store.getters.assignment(value);
    }

    if (currentAssignmentTable) {
      let payload = {
        name: currentAssignmentTable.name,
        values:
          typeof returnValue !== "string"
            ? returnValue
            : currentAssignmentTable.values
      };

      // Skip updating the assignment table if its values have not been changed.
      if (_.isEqual(payload["values"], currentAssignmentTable.values)) {
        return currentAssignmentTable.id;
      }

      // update
      await Assignments.update(currentAssignmentTable.id, payload).then(
        response => {
          returnValue = response.data?.id;
          assignmentTables.value[assignmentTableName] = response.data;
        }
      );
    } else {
      // create
      await Assignments.store({
        name: assignmentTableName,
        values: value ?? {},
        projects: [selectedIntegration.value.id]
      }).then(response => {
        returnValue = response.data?.id;
        assignmentTables.value[assignmentTableName] = response.data;
      });
    }

    return returnValue;
  }

  return { assignmentTables, resolveAssignmentTable, saveAssignmentTable };
};
