import ApiService from "@/core/services/api.service";

let url = process.env.VUE_APP_API_ADMIN;

class Reporting {
  get(id, flat = false, days = 7) {
    let params = id;
    params += "?days=" + days;
    if (flat) {
      params += "&flat=true";
    }
    return ApiService.get(url, "reporting/process/" + params);
  }

  getChildren(id, days = 7) {
    let params = "?days=" + days;
    return ApiService.get(
      url,
      "reporting/process/" + id + "/children" + params
    );
  }

  getAll(params) {
    let options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "reporting/process" + options);
  }

  getIterations(processId, params, headers = {}, disableAbort = false) {
    return ApiService.post(
      url + "/reporting/flow_executions/" + processId,
      params,
      headers,
      false,
      disableAbort
    );
  }
}

export default new Reporting();
