import { useStore } from "@/core/services/store";

const store = useStore();

export const loadProjects = () => {
  return store.dispatch("loadProjects");
};

export const loadForms = (payload = {}) => {
  return store.dispatch("loadForms", payload);
};

export const loadAssignments = async () => {
  return await store.dispatch("loadAssignments");
};
export const setSelectedApp = async name => {
  await store.dispatch("setSelectedApp", name);
};

export const setXentralConfig = config => {
  return store.dispatch("setXentralConfig", config);
};

export const loadSelectedIntegration = integration => {
  return store.dispatch("loadSelectedIntegration", integration);
};

export const setValuesToSave = payload => {
  return store.dispatch("setValuesToSave", payload);
};

export const updateOauthEnvironment = payload => {
  return store.dispatch("updateOauthEnvironment", payload);
};

export const resetValuesToSave = () => {
  return store.dispatch("resetValuesToSave");
};

export const addDeactivatedFeature = payload => {
  return store.dispatch("addDeactivatedFeature", payload);
};

export const resetDeactivatedFeatures = () => {
  return store.dispatch("resetDeactivatedFeatures");
};
