import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

let url = process.env.VUE_APP_API_ADMIN;

class ProcessIteration extends BaseService {
  get(id, fields = [], optionalHeaders) {
    let params = {};

    if (fields.length > 0 && Array.isArray(fields)) {
      for (const [index, field] of fields.entries()) {
        params["_fields[" + index + "]"] = field;
      }
    }

    const urlSearchParams = params
      ? new URLSearchParams(params).toString()
      : "";

    return ApiService.get(
      url,
      "flow_executions/" + id + "?" + urlSearchParams,
      optionalHeaders
    );
  }
  getAll(params, searchTerm = null, optionalHeaders = {}) {
    let postData = {
      page: params.page,
      perPage: params.perPage,
      status: params.status,
      filter: params.filter,
      searchTerm: params.searchTerm
    };

    if (params.hasParameters) {
      postData.hasParameters = params.hasParameters;
    }

    if (params.createdTo > 0) {
      postData.to = params.createdTo;
    }

    if (params.createdFrom > 0) {
      postData.from = params.createdFrom;
    }

    if (searchTerm !== null) {
      postData.searchTerm = searchTerm;
    }

    return ApiService.post(url + "/flow_executions", postData, optionalHeaders);
  }

  exportCsv(id, params) {
    return ApiService.post(
      url + "/flow_executions/" + id + "/export_csv",
      params
    );
  }

  getByParentIteration(parentIterationId, params) {
    let postData = {
      page: params.page,
      perPage: params.perPage,
      searchTerm: params.searchTerm,
      filter: [
        {
          key: "parent_flow_execution_id",
          op: "equals",
          value: parentIterationId
        }
      ]
    };
    let headers = {};
    return ApiService.post(url + "/flow_executions", postData, headers);
  }

  requeue(id) {
    return ApiService.post(url + `/flow_executions/${id}/requeue`);
  }

  resume(id) {
    return ApiService.post(url + `/flow_executions/${id}/resume`);
  }

  bulkRequeue(data) {
    return ApiService.post(url + "/flow_executions/requeue", data);
  }

  bulkRequeueCount(data) {
    return ApiService.post(url + "/flow_executions/requeue/count", data);
  }

  abort(id) {
    return ApiService.post(url + `/flow_executions/${id}/abort`);
  }
}
export default new ProcessIteration();
