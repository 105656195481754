<script setup>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useStore } from "@/core/services/store";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { firstMigrationSyncFields } from "@/components/ExternalApps/SalesChannelManagementApp/utility/constants";
import { labelData } from "@/components/ExternalApps/SalesChannelManagementApp/utility/labelData";

const { selectedIntegration, formValueChange } = useIntegration();
const store = useStore();
const { features } = useFeatures();

function onChangeMigrationForm(value, key) {
  formValueChange(value, "migration", key);

  const isFirstMigration = !(
    selectedIntegration.value.id in
    store.getters["variables/configValueByName"]("migrationChecks").value
  );

  let productTaxesPresetValue =
    selectedIntegration?.value?.product?.taxes?.value;

  if (
    isFirstMigration &&
    firstMigrationSyncFields.includes(value.name) &&
    productTaxesPresetValue
  ) {
    productTaxesPresetValue[value.name] = value.value;
    formValueChange(value, "product", "taxes", features.value);
  }
}
</script>

<template>
  <div>
    <div>
      <FormHelper
        v-for="(migrationForm, key) in store.getters.form('migration')"
        :key="key"
        ref="migrationForms"
        v-model="selectedIntegration.migration.value[key]"
        :config="{
          title: $t('salesChannelManagementApp.titles.migration.' + key),
          snippetPrefix: 'salesChannelManagementApp',
          loadingGroup: 'salesChannelManagementApp',
          ...labelData
        }"
        :form="migrationForm"
        @change="onChangeMigrationForm($event, key)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-helper {
  border-bottom: 1px solid #d0d5dd;
  padding: 1rem 0 2rem 0;
  margin: 1rem 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
  }
}
</style>
