import { ref, watchEffect } from "vue";
import { useStore } from "@/core/services/store";
import { useRoute } from "vue-router/composables";

const store = useStore();

export const useAppLogo = () => {
  const logo = ref();
  const route = useRoute();

  watchEffect(() => {
    logo.value = store.getters.selectedApp?.logo
      ? process.env.BASE_URL + "/" + store.getters.selectedApp.logo
      : process.env.BASE_URL + "/media/shopLogos/" + route.params.name + ".png";
  });

  return { logo };
};
