<script setup>
import ApiService from "@/core/services/api.service";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import router from "@/router";
import { loadProjects } from "@/composables/useSalesChannelManagementApp";
import i18n from "@/core/plugins/vue-i18n";
import { Confirmation, Toast } from "@/core/plugins/swal";
import { useStore } from "@/core/services/store";

const { selectedIntegration } = useIntegration();

async function handleDelete() {
  const url =
    process.env.VUE_APP_API_ADMIN +
    "/projects/scma/" +
    selectedIntegration.value.id;

  const params = {
    text: i18n.t("salesChannelManagementApp.settings.deletionText", {
      integration: selectedIntegration.value.name
    }),
    icon: "error",
    showCancelButton: true,
    confirmButtonColor: "var(--primary)"
  };
  const result = await Confirmation(params);
  if (!result.isConfirmed) return;

  let deleted = await ApiService.delete(url);

  if (!deleted.success) {
    await Toast.fire({
      icon: "error",
      title: i18n.t("salesChannelManagementApp.settings.deletionError")
    });
    return;
  }
  await Toast.fire({
    icon: "success",
    title: i18n.t("salesChannelManagementApp.settings.deletionSuccess")
  });
  await loadProjects();
  const store = useStore();
  await router.push({
    name: "salesChannelManagementApp",
    params: { name: store.getters.selectedApp.name }
  });
}
</script>

<template>
  <div>
    <h5 class="font-weight-bold mb-5">
      {{ $t("salesChannelManagementApp.settings.dangerZone") }}
    </h5>
    <div class="col-form-label">
      {{ $t("salesChannelManagementApp.settings.dangerZoneInfo") }}
    </div>
    <button class="btn btn-danger" @click="handleDelete">
      {{ $t("general.delete") }}
    </button>
  </div>
</template>

<style lang="scss" scoped></style>
