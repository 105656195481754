import { productSelectionVariables } from "@/components/ExternalApps/SalesChannelManagementApp/utility/productSelectionVariables";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArea";

export const useConditions = () => {
  const { selectedIntegration } = useIntegration();
  const { selectedArea } = useArea();

  /**
   * @param {object} payload
   */
  function resolveConditions(payload) {
    const currentField =
      selectedIntegration.value.productSelection?.[selectedArea.value]?.value?.[
        payload.name
      ];

    resolveConditionChildren(currentField);
  }

  /**
   * @param {object} condition
   * @param {string} source
   * @param {string} target
   */
  function swapValues(condition, source, target) {
    const variables = productSelectionVariables;
    const currentVariableField = variables.find(
      variable => variable[source] === condition.field
    );
    const currentVariableValue = variables.find(
      variable => variable[source] === condition.value
    );

    if (currentVariableField?.[target]) {
      condition.field = currentVariableField[target];
    }
    if (currentVariableValue?.[target]) {
      condition.value = currentVariableValue[target];
    }

    if (currentVariableField?.value) {
      condition.value = swapValue(
        condition,
        currentVariableField.value,
        source,
        target
      );
    }

    if (condition?.children) {
      for (const child of condition?.children || []) {
        swapValues(child, source, target);
      }
    }
  }

  /**
   * @param {object} condition
   * @param {object} valueObject
   * @param {string} source
   * @param {string} target
   * @returns {string|number|boolean}
   */
  function swapValue(condition, valueObject, source, target) {
    const currentValue = condition.value;
    const currentSetValueObject = valueObject.find(
      entry => entry?.[source] === currentValue
    );

    return currentSetValueObject?.[target] ?? currentValue;
  }

  /**
   * @param {object} field
   */
  function resolveConditionChildren(field) {
    for (const child of field?.children || []) {
      swapValues(child, "field", "text");
    }
  }

  /**
   * @param {object} field
   * @param {object|string|number|boolean|array|null} value
   * @returns {Promise<object|string|number|boolean|array|null>}
   */
  async function saveConditions(field, value) {
    if (value === null) return value;

    let returnValue = value ?? {};

    if (!Object.keys(returnValue).length) return returnValue;
    returnValue = saveConditionChildren(returnValue);

    return returnValue;
  }

  /**
   * @param {object} returnValue
   * @returns {object}
   */
  function saveConditionChildren(returnValue) {
    for (const child of returnValue?.children || []) {
      swapValues(child, "text", "field");
    }

    return returnValue;
  }

  return { resolveConditions, saveConditions, swapValues };
};
