<script setup>
import {
  computed,
  defineProps,
  defineEmits,
  getCurrentInstance,
  ref,
  watch,
  nextTick
} from "vue";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import TableHelperFilter from "@/components/Tools/TableHelper/TableHelperFilter.vue";
import { Toast } from "@/core/plugins/swal";

const i18n = getCurrentInstance().proxy.$i18n;
const props = defineProps({
  value: {
    type: Boolean,
    required: true
  },
  fields: {
    type: Array,
    required: true
  },
  props: {
    type: Object,
    required: true
  }
});
const emits = defineEmits(["input", "export-csv"]);
const tableHelper = ref(null);
const show = computed({
  get: () => props.value,
  set: value => {
    emits("input", value);
  }
});
const selectedFields = ref([]);
const selectedFilters = ref([]);
const tableHelperFields = ref([
  {
    key: "name",
    label: i18n.t("tableHelper.export.columnName")
  },
  {}
]);
const tableHelperItems = computed(() => {
  return props.fields.map(field => ({
    name: field
  }));
});
function setSelectedFields(fields) {
  if (!Array.isArray(fields)) {
    return;
  }
  selectedFields.value = fields.map(field => field.name);
}
function exportData() {
  const data = {
    fields: selectedFields.value,
    filters: selectedFilters.value
  };
  emits("export-csv", data);
  Toast.fire({
    icon: "info",
    title: i18n.t("tableHelper.export.onExport")
  });
}
function setFilters(filters) {
  selectedFilters.value = filters;
}
function resetFilters() {
  selectedFilters.value = [];
}
watch(
  () => show.value,
  value => {
    if (value) {
      nextTick().then(() => {
        tableHelper.value.allRowsSelected = [true];
        tableHelper.value.selectAllRows();
      });
    }
  }
);
</script>

<template>
  <div>
    <b-modal
      v-model="show"
      content-class="export-modal"
      size="xl"
      :title="$t('tableHelper.export.title')"
      :no-close-on-backdrop="true"
      :ok-title="$t('tableHelper.export.export')"
      :ok-disabled="!selectedFields.length"
      :cancel-title="$t('general.cancel')"
      @ok="exportData"
    >
      <!------------ START: Column selection ------------>
      <TableHelper
        ref="tableHelper"
        class="table"
        loading-key="test"
        height="350px"
        :items="tableHelperItems"
        :fields="tableHelperFields"
        :disable-page-size="true"
        :disable-search="true"
        :enable-filter="false"
        :enable-mass-delete="false"
        :enable-pagination="false"
        :enable-select="true"
        :enable-reload="false"
        :hide-header="true"
        :use-card="false"
        @select-row="setSelectedFields"
        @select-all-rows="setSelectedFields"
      />
      <p>
        {{ $t("tableHelper.export.selectedColumns") }}:
        <span class="text-muted">
          {{
            selectedFields.length
              ? selectedFields.join(", ")
              : $t("tableHelper.export.noColumnsSelected")
          }}
          {{ selectedFields.length === fields.length ? "(*)" : "" }}
        </span>
      </p>
      <!------------ END: Column selection ------------>
      <!------------ START: Data filtering ------------>
      <p>{{ $t("tableHelper.export.selectFilters") }}</p>
      <TableHelperFilter
        :filterable="props.props.filterable"
        :field-blacklist="props.props.filterableFieldBlacklist"
        :param-prefix="props.props.paramPrefix"
        @execute-filter="setFilters"
        @reset-filter="resetFilters"
      />
      <!------------ END: Data filtering ------------>
    </b-modal>
  </div>
</template>

<style lang="scss"></style>
