<script setup>
import { defineProps, defineEmits, ref } from "vue";

const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  translatePrefix: {
    type: String,
    required: true
  }
});

const emit = defineEmits(["on-select-tab"]);

const selectedItem = ref(props.items.at(0).key);

function onSelectTab(item) {
  selectedItem.value = item;

  emit("on-select-tab", item);
}
</script>

<template>
  <div>
    <div class="tabs d-flex justify-content-between mb-4">
      <PerfectScrollbar class="d-flex align-end">
        <button
          v-for="(item, i) in items"
          :key="i"
          class="tab-title py-2 px-4"
          :class="selectedItem === item.key ? 'active' : ''"
          :disabled="item.disabled"
          @click="onSelectTab(item.key)"
        >
          {{ $t(translatePrefix + "." + item.key) }}
        </button>
      </PerfectScrollbar>
    </div>

    <slot />
  </div>
</template>

<style scoped lang="scss">
.tabs {
  border-bottom: 1px solid #d0d5dd;

  .tab-title {
    color: $color-connect-text;
    font-weight: bold;
    border: none;
    cursor: pointer;
    white-space: nowrap;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.active,
    &:hover {
      box-shadow: 0 -2px 0 $color-connect-primary inset;
      color: $color-connect-primary;
      text-decoration: none;
      font-weight: bold;
    }

    &.active {
      &:hover {
        color: $color-connect-primary;
      }
    }

    &:hover {
      color: $color-connect-text;
    }
  }
}
</style>
