<script setup>
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import { computed, onMounted, ref, watch } from "vue";
import ProcessIteration from "@/components/Workflows/processIteration";
import { Error } from "@/core/plugins/swal";
import i18n from "@/core/plugins/vue-i18n";
import { useStore } from "@/core/services/store";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { useArticleImport } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArticleImport";

const store = useStore();
const { articleImportPending } = useArticleImport();
const requestParams = store.getters["route/requestParams"];

const CFL_WORKFLOW_ID = computed(
  () => store.getters.environmentVariables.CFLWorkflowId
);

const stoppedIterations = ref([]);
const meta = ref({});
const fields = ref([
  {
    key: "case_identifier",
    label: i18n.t("salesChannelManagementApp.articleCount"),
    sortable: true
  },
  {
    key: "finished_at",
    label: i18n.t("salesChannelManagementApp.date"),
    sortable: true,
    type: "datetime"
  }
]);

function getAllFinishedIterations() {
  requestParams.filter = [
    {
      key: "status",
      op: "equals",
      value: ["process.stopped"]
    },
    {
      key: "content_identifier",
      op: "equals",
      value: "Finished"
    },
    {
      key: "process_id",
      op: "equals",
      value: CFL_WORKFLOW_ID.value
    }
  ];

  addEventToLoadingQueue({ key: "loadJobs" });

  ProcessIteration.getAll(requestParams)
    .then(response => {
      stoppedIterations.value = response.data;
      meta.value = response.meta;
    })
    .catch(error => {
      Error(error);
    })
    .finally(() => {
      removeEventFromLoadingQueue({ key: "loadJobs" });
    });
}

watch(
  () => articleImportPending.value,
  () => getAllFinishedIterations()
);

onMounted(() => {
  getAllFinishedIterations();
});
</script>

<template>
  <div>
    <TableHelper
      :fields="fields"
      :items="stoppedIterations"
      :meta="meta"
      disable-search
      disable-skeleton-loading
      loading-key="loadJobs"
      :title="$t('salesChannelManagementApp.usedFilters')"
      @reload-data="getAllFinishedIterations"
    />
  </div>
</template>

<style lang="scss" scoped></style>
