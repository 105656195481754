import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

let url = process.env.VUE_APP_API_ADMIN;

class DataSets extends BaseService {
  get(id) {
    return ApiService.get(url, "data_sets/" + id);
  }

  getAll(params = { page: 1, size: 10 }, filter = []) {
    let filterParams = this.getParams(filter, params);
    let options = new URLSearchParams(params).toString();
    let headers = {};

    return ApiService.get(url, "data_sets?" + options + filterParams, headers);
  }

  store(data) {
    return ApiService.post(url + "/data_sets", data);
  }

  update(id, data) {
    for (const stage of data.dataSetStages) {
      stage.conditions = stage?.conditions ?? [];
    }

    return ApiService.put(url + "/data_sets/" + id, data);
  }

  export(id, type) {
    return ApiService.post(url + "/export/" + type + "/dataSet/" + id);
  }

  import(data) {
    // FormData needs to be sent without a content type
    ApiService.removeHeaderByKey("Content-Type");
    return ApiService.post(url + "/import", data, {}, true);
  }

  delete(id) {
    return ApiService.delete(url + "/data_sets/" + id);
  }

  execute(id, data, optionalHeaders = {}) {
    return ApiService.post(
      `${url}/data_sets/${id}/execute`,
      data,
      optionalHeaders
    );
  }

  run(id) {
    return ApiService.post(url + "/data_sets/" + id);
  }

  config() {
    return ApiService.get(url, "data_sets/config");
  }

  stages() {
    return ApiService.get(url, "data_sets/stages");
  }

  runStages(data) {
    for (const stage of data.dataSetStages) {
      stage.conditions = stage?.conditions ?? [];
    }

    return ApiService.post(url + "/data_sets/executeStages", data);
  }

  exportCsv(params) {
    return ApiService.post(url + "/data_sets/export_csv", params);
  }
}

export default new DataSets();
