import { ref } from "vue";
import { useAssignmentTables } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAssignmentTables";
import { useConditions } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useConditions";

const { resolveAssignmentTable } = useAssignmentTables();
const { resolveConditions } = useConditions();

export const useLoadFormatters = () => {
  const loadFormatters = ref({ resolveAssignmentTable, resolveConditions });

  function loadFormatter(payload) {
    if (payload.type) {
      loadFormatters.value[payload.type](payload.field, payload.value);
    }
  }

  return { loadFormatter };
};
