<template>
  <!-- begin::Page loader -->
  <img :src="logo" alt="" class="logo-height-mini spin" />
  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: "ProjectsWorkflowDesignerCanvasComponentsLoader",
  data() {
    return {};
  },
  computed: {
    logo() {
      return process.env.BASE_URL + "media/logos/logo_icon.svg";
    }
  }
};
</script>

<style lang="scss" scoped>
.logo-height-mini {
  position: fixed;
  // Because of the spin animation we cannot translate the item,
  // therefore we need a lower percentage for the left positioning
  left: 48%;
  top: 50%;
  width: 3rem;
  height: 3rem;
}
</style>
