<script setup>
import { useArticleImport } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArticleImport";
import { onMounted, onUnmounted } from "vue";
import ArticleProgressBar from "@/components/ExternalApps/SalesChannelManagementApp/components/ArticleProgressBar.vue";

const {
  articleImportPending,
  currentArticleImportStatus,
  isMounted,
  checkForPendingWorkingJobs,
  timeout
} = useArticleImport();

onMounted(() => {
  isMounted.value = true;
  checkForPendingWorkingJobs();
});

onUnmounted(() => {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
  isMounted.value = false;
  articleImportPending.value = false;
});
</script>

<template>
  <ArticleProgressBar
    :current-status="currentArticleImportStatus"
    :title="
      $t('salesChannelManagementApp.journal.articleListBuilding').toString()
    "
    :info="
      $t('salesChannelManagementApp.journal.articleListBuildingInfo').toString()
    "
  />
</template>

<style lang="scss" scoped></style>
