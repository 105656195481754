<script setup>
import MigrationForms from "@/components/ExternalApps/SalesChannelManagementApp/components/Forms/MigrationForms.vue";
import SkeletonLoading from "@/components/ExternalApps/SalesChannelManagementApp/components/Forms/SkeletonLoading.vue";
import { useAppStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppStatus";
import { useFeatureArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatureArea";
import { computed, watch } from "vue";
import { useArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArea";
import Tabs from "@/components/Tools/Tabs.vue";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import { useMigrationReporting } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigrationReporting";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { useMigration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigration";
import { migrationActionAreas } from "@/components/ExternalApps/SalesChannelManagementApp/utility/constants";
import Modal from "@/components/Tools/Modal.vue";

const { isAppInitialized } = useAppStatus();
const { selectMigration } = useFeatureArea();
const { selectedArea } = useArea();
const migrationAreas = computed(() => [
  {
    key: "settings"
  },
  {
    key: "export"
  },
  {
    key: "import",
    disabled: !migrationStepFinished.value.export
  }
]);
const {
  migrationStepFinished,
  onExecuteMigrationWorkflow,
  runMigrationWorkflow,
  showModal,
  singleMigrationForm,
  singleMigrationConfig,
  singleMigrationData
} = useMigration();
const {
  fields: migrationReportingFields,
  data: migrationReportingData,
  meta: migrationReportingMeta,
  filterable: migrationReportingFilterable,
  paramPrefix,
  executeDataSet
} = useMigrationReporting();

function onSelectTab(area) {
  selectedArea.value = area;

  if (
    !migrationActionAreas.includes(selectedArea.value) ||
    migrationReportingData.value?.[selectedArea.value].length
  ) {
    return;
  }

  executeDataSet();
}

watch(
  () => isAppInitialized.value,
  () => {
    selectMigration();
  }
);
</script>

<template>
  <div>
    <div
      v-if="
        (!migrationStepFinished.export || !migrationStepFinished.import) &&
        isAppInitialized
      "
      class="alert alert-info bg-white"
    >
      <i class="fal fa-warning"></i>
      <span class="ml-2">
        <template v-if="!migrationStepFinished.export">
          {{
            $t("salesChannelManagementApp.reporting.migrationExportNotFinished")
          }}
        </template>
        <template v-else-if="!migrationStepFinished.import">
          {{
            $t("salesChannelManagementApp.reporting.migrationImportNotFinished")
          }}
        </template>
      </span>
    </div>

    <SkeletonLoading v-if="!isAppInitialized" />

    <Tabs
      v-else
      :items="migrationAreas"
      translate-prefix="salesChannelManagementApp.reporting"
      @on-select-tab="onSelectTab($event)"
    >
      <div v-if="selectedArea === 'settings'">
        <MigrationForms />
      </div>
      <div v-else-if="migrationActionAreas.includes(selectedArea)">
        <TableHelper
          :key="'migration-reporting-' + selectedArea"
          loading-key="migration-reporting"
          :items="migrationReportingData?.[selectedArea] ?? []"
          :fields="migrationReportingFields ?? []"
          :meta="migrationReportingMeta?.[selectedArea] ?? {}"
          :filterable="migrationReportingFilterable"
          :param-prefix="paramPrefix"
          :no-route-params="true"
          :use-card="false"
          :enable-filter="true"
          :disable-search="true"
          :enable-select="false"
          @reload-data="executeDataSet"
          @execute-migration-workflow="onExecuteMigrationWorkflow"
        >
          <template #afterToolbar>
            <div class="migration-buttons">
              <button class="btn btn-primary" @click="runMigrationWorkflow()">
                {{ $t("salesChannelManagementApp.reporting.migrationStart") }}
              </button>

              <button class="btn btn-primary" @click="showModal = true">
                {{
                  $t("salesChannelManagementApp.reporting.migrationSingleStart")
                }}
              </button>
            </div>
          </template>
        </TableHelper>

        <Modal
          :show-modal="showModal"
          :title="
            $t('salesChannelManagementApp.reporting.migrationSingleStart')
          "
          @toggle-modal="showModal = $event"
        >
          <template #content>
            <FormHelper
              ref="migrationSingleForm"
              v-model="singleMigrationData"
              :config="singleMigrationConfig"
              :form="singleMigrationForm"
            />
          </template>

          <template #footer>
            <button
              class="btn btn-primary"
              @click="runMigrationWorkflow('single')"
            >
              {{
                $t(
                  "salesChannelManagementApp.reporting.executeMigrationWorkflow",
                  {
                    action: $t(
                      "salesChannelManagementApp.reporting." + selectedArea
                    )
                  }
                )
              }}
            </button>
          </template>
        </Modal>
      </div>
    </Tabs>
  </div>
</template>

<style lang="scss" scoped>
.migration-buttons {
  display: flex;
  gap: 20px;
}
</style>
