import { computed, ref } from "vue";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { Confirmation, Error } from "@/core/plugins/swal";
import { useStore } from "@/core/services/store";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import ProcessManager from "@/components/Workflows/processManager";
import { useArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArea";
import i18n from "@/core/plugins/vue-i18n";
import Config from "@/components/Settings/Config/config";
import { LOAD_CONFIG_VALUES } from "@/core/services/store/variables_v1.module";
import { useRouter } from "vue-router/composables";
import { useUtility } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useUtility";
import { processStatusToIgnore } from "@/components/ExternalApps/SalesChannelManagementApp/utility/constants";

const { selectedIntegration, selectedIntegrationName, formValueChange } =
  useIntegration();
const { selectedArea } = useArea();
const store = useStore();

const migrationStepFinished = ref({
  export: false,
  import: false
});
const migrationWorkflowId = computed(() =>
  store.getters.migrationWorkflowId(selectedArea.value)
);
const migrationSingleWorkflowId = computed(() =>
  store.getters.migrationWorkflowId(selectedArea.value, "single")
);
const showModal = ref(false);
const singleMigrationConfig = {
  labelStacked: true,
  snippetPrefix: "salesChannelManagementApp"
};
const singleMigrationForm = [
  {
    name: "productNumber",
    type: "text",
    label: "reporting.productNumber",
    validations: {
      required: true
    }
  }
];
const singleMigrationData = ref();
const migrationChecks = computed(() =>
  store.getters["variables/configValueByName"]("migrationChecks")
);

const migrationStatus = ref({
  active:
    !migrationChecks?.value?.value?.[selectedIntegration.value.id]
      ?.migrationFinished ?? false
});
const migrationStatusForm = [
  {
    name: "active",
    type: "checkbox",
    label: "migration.migrationActive"
  }
];
const { xentralProjectHeader } = useUtility();

export const useMigration = () => {
  const router = useRouter();

  async function finishMigration() {
    const params = {
      title: i18n.t("salesChannelManagementApp.migration.finish"),
      text: i18n.t("salesChannelManagementApp.migration.finishDescription"),
      cancelButtonText: i18n.t("general.cancel"),
      confirmButtonText: i18n.t("general.confirm")
    };

    const result = await Confirmation(params);
    if (!result.isConfirmed) return;

    await executeFinishMigration();
  }

  async function executeFinishMigration() {
    try {
      addEventToLoadingQueue({ key: "finish-migration" });
      await setMigrationChecksConfigValue(selectedIntegration.value.id);

      await store.dispatch("variables/" + LOAD_CONFIG_VALUES);

      removeEventFromLoadingQueue({
        key: "finish-migration",
        type: "success",
        prefix: "salesChannelManagementApp",
        name: "migration.finished"
      });

      await router.push({ name: "salesChannelManagementApp" });
    } catch (error) {
      Error(error);
    } finally {
      removeEventFromLoadingQueue({ key: "finish-migration" });
    }
  }

  async function setMigrationChecksConfigValue(id) {
    const value = { [id]: { migrationFinished: true } };
    const migrationChecks =
      store.getters["variables/configValueByName"]("migrationChecks");

    if (migrationChecks) {
      migrationChecks.value = {
        ...migrationChecks.value,
        ...value
      };
      await Config.update(migrationChecks.id, migrationChecks);
    } else {
      await Config.store({
        name: "migrationChecks",
        label: "migrationChecks",
        type: "json",
        value: value
      });
    }
  }

  async function runMigrationWorkflow(type = "all") {
    showModal.value = false;
    addEventToLoadingQueue({
      key: "run-migration-workflow",
      group: "run-migration-workflow"
    });

    let params = {
      channelSpecificName: selectedIntegrationName.value
    };
    if (type === "single") {
      params["productNumber"] = singleMigrationData.value.productNumber;
    }

    const id =
      type === "all"
        ? migrationWorkflowId.value
        : migrationSingleWorkflowId.value;

    const optionalHeaders =
      selectedArea.value === "import" ? xentralProjectHeader.value : {};

    try {
      await ProcessManager.run(
        id,
        {
          params: params
        },
        optionalHeaders
      );

      const isPending = await getWorkflowPendingStatus(selectedArea.value);
      migrationStepFinished.value[selectedArea.value] = isPending ?? false;

      removeEventFromLoadingQueue({
        group: "run-migration-workflow",
        type: "success",
        prefix: "salesChannelManagementApp",
        name: "migration.started"
      });
    } catch (error) {
      Error(error);
    } finally {
      removeEventFromLoadingQueue({ group: "run-migration-workflow" });
    }
  }

  async function getWorkflowPendingStatus(area) {
    const id = store.getters.migrationWorkflowId(area, "single");
    const optionalHeaders = area === "import" ? xentralProjectHeader.value : {};
    const response = await ProcessManager.get(id, false, optionalHeaders, true);

    const iterationStatus = response?.data?.execution_count?.status || {};
    const pendingIterations = Object.entries(iterationStatus).filter(
      ([key, iterationCount]) =>
        !processStatusToIgnore.includes(key) && iterationCount > 0
    );

    return pendingIterations.length <= 0;
  }

  async function onExecuteMigrationWorkflow(data) {
    const { item: { productNumber = undefined } = {} } = data;

    await runMigrationWorkflow(productNumber);
  }

  async function onChangeMigrationStatus(event) {
    const params = {
      title: i18n.t(
        "salesChannelManagementApp.migration.changeMigrationActiveStatusTitle"
      ),
      text: !migrationStatus.value.active
        ? i18n.t(
            "salesChannelManagementApp.migration.changeMigrationActiveStatusTextInactive"
        )
        : i18n.t(
          "salesChannelManagementApp.migration.changeMigrationActiveStatusTextActive"
        ),
      cancelButtonText: i18n.t("general.cancel"),
      confirmButtonText: i18n.t("general.confirm")
    };

    const response = await Confirmation(params);

    if (!response.isConfirmed) {
      migrationStatus.value.active = event.old;
      return;
    }

    if (selectedIntegration.value.baseData.value.active && event.value) {
      selectedIntegration.value.baseData.value.active = false;
      formValueChange(selectedIntegration.value.baseData.value, "baseData");
    }
  }

  async function handleMigrationOnSave() {
    if (
      !(selectedIntegration.value.id in (migrationChecks.value?.value || {})) ||
      migrationStatus.value.active !==
        migrationChecks?.value?.value?.[selectedIntegration.value.id]
          ?.migrationFinished
    ) {
      return;
    }

    if (migrationStatus.value?.active) {
      selectedIntegration.value.baseData.value.active = false;
    }

    let migrationChecksCopy = JSON.parse(
      JSON.stringify(migrationChecks?.value)
    );

    const value = {
      [selectedIntegration.value.id]: {
        migrationFinished: !migrationStatus.value?.active
      }
    };

    migrationChecksCopy.value = {
      ...migrationChecksCopy.value,
      ...value
    };

    await Config.update(migrationChecksCopy.id, migrationChecksCopy);
    await store.dispatch("variables/" + LOAD_CONFIG_VALUES);
  }

  return {
    finishMigration,
    getWorkflowPendingStatus,
    migrationStepFinished,
    onExecuteMigrationWorkflow,
    runMigrationWorkflow,
    onChangeMigrationStatus,
    showModal,
    singleMigrationConfig,
    singleMigrationData,
    singleMigrationForm,
    migrationChecks,
    migrationStatus,
    migrationStatusForm,
    handleMigrationOnSave,
    setMigrationChecksConfigValue
  };
};
