<script setup>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArea";
import { useStore } from "@/core/services/store";
import { useLoadFormatters } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useLoadFormatters";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { useArticleImport } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArticleImport";
import Modal from "@/components/Tools/Modal.vue";
import i18n from "@/core/plugins/vue-i18n";
import ProductSelectionRunCFLConfirmation from "@/components/ExternalApps/SalesChannelManagementApp/components/Areas/ProductSelectionRunCFLConfirmation.vue";
import { labelData } from "@/components/ExternalApps/SalesChannelManagementApp/utility/labelData";

const store = useStore();
const { selectedArea } = useArea();
const { selectedFeature } = useFeatures();
const { selectedIntegration, formValueChange } = useIntegration();
const { loadFormatter } = useLoadFormatters();

const {
  importSpecificArticle,
  showArticleImportModal,
  specificArticleImportModel,
  showArticleImportPrepareModal,
  currentArticleImportPrepareModalStep
} = useArticleImport();

const formConfig = ref({
  snippetPrefix: "salesChannelManagementApp",
  labelColClass: "col-5",
  fieldColClass: "col-7",
  fieldColClassXl: "col-7",
  ...labelData.value
});

const form = [
  {
    name: "productNumbers",
    type: "text",
    label: i18n.t("salesChannelManagementApp.productSelection.productNumbers"),
    infoText: i18n.t(
      "salesChannelManagementApp.productSelection.productNumbersHelp"
    ),
    disableReturnType: true,
    validations: {
      required: true
    }
  }
];

watch(
  () => showArticleImportPrepareModal.value,
  () => {
    if (!showArticleImportPrepareModal.value) {
      currentArticleImportPrepareModalStep.value = "prepare";
    }
  }
);

onMounted(async () => {
  selectedFeature.value = "productSelection";
  selectedArea.value = "selection";
});

onUnmounted(() => (showArticleImportModal.value = false));
</script>

<template>
  <div>
    <div v-if="selectedIntegration?.productSelection?.selection">
      <FormHelper
        key="productSelection-selection"
        v-model="selectedIntegration.productSelection.selection.value"
        :config="{
          snippetPrefix: 'salesChannelManagementApp',
          title: $t(
            'salesChannelManagementApp.titles.productSelection.configure'
          ),
          configValues: false,
          customVariables: ['scmaXentralFields'],
          ...labelData,
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp'
        }"
        :form="store.getters.form('productSelection.selection') || {}"
        @change="
          formValueChange($event, 'productSelection', 'selection');
          formValueChange($event, 'productSelection', 'selectionV2');
        "
        @load-formatter="loadFormatter"
      />

      <Modal
        :show-modal="showArticleImportPrepareModal"
        width="600"
        :title="
          currentArticleImportPrepareModalStep === 'prepare'
            ? $t('salesChannelManagementApp.useFilter')
            : undefined
        "
        :show-footer="currentArticleImportPrepareModalStep === 'prepare'"
        @toggle-modal="showArticleImportPrepareModal = false"
      >
        <template #content>
          <FormHelper
            v-if="currentArticleImportPrepareModalStep === 'prepare'"
            v-model="specificArticleImportModel"
            :config="formConfig"
            :form="form"
          />
          <ProductSelectionRunCFLConfirmation
            v-else-if="currentArticleImportPrepareModalStep === 'import'"
          />
        </template>

        <template #footer>
          <button class="btn btn-primary mr-2" @click="importSpecificArticle">
            {{ $t("salesChannelManagementApp.next") }}
          </button>
        </template>
      </Modal>

      <Modal
        id="status-modal"
        width="600"
        :show-modal="showArticleImportModal"
        :show-footer="false"
        @toggle-modal="showArticleImportModal = false"
      >
        <template #content>
          <ProductSelectionRunCFLConfirmation />
        </template>
      </Modal>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
