export const areasWithoutMigration = ["workflows", "productSelection"];

export const migrationActionAreas = ["export", "import"];

export const processStatusToIgnore = [
  "process.stopped",
  "process.aborted",
  "process.error"
];

export const firstMigrationSyncFields = ["taxRateNormal", "taxRateReduced"];

export const salesOrderFeatures = ["salesOrder"];

export const importStartedStates = ["importStarted", "stateImportStarted"];

export const exportStartedStates = ["exportStarted", "stateExportStarted"];

export const completeStartedStates = [
  ...importStartedStates,
  ...exportStartedStates
];
