var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.selectedIntegration?.productSelection?.selection)?_c('div',[_c(_setup.FormHelper,{key:"productSelection-selection",attrs:{"config":{
        snippetPrefix: 'salesChannelManagementApp',
        title: _vm.$t(
          'salesChannelManagementApp.titles.productSelection.configure'
        ),
        configValues: false,
        customVariables: ['scmaXentralFields'],
        ..._setup.labelData,
        labelColClass: 'col-5',
        fieldColClass: 'col-7',
        fieldColClassXl: 'col-7',
        loadingGroup: 'salesChannelManagementApp'
      },"form":_setup.store.getters.form('productSelection.selection') || {}},on:{"change":function($event){_setup.formValueChange($event, 'productSelection', 'selection');
        _setup.formValueChange($event, 'productSelection', 'selectionV2');},"load-formatter":_setup.loadFormatter},model:{value:(_setup.selectedIntegration.productSelection.selection.value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration.productSelection.selection, "value", $$v)},expression:"selectedIntegration.productSelection.selection.value"}}),_c(_setup.Modal,{attrs:{"show-modal":_setup.showArticleImportPrepareModal,"width":"600","title":_setup.currentArticleImportPrepareModalStep === 'prepare'
          ? _vm.$t('salesChannelManagementApp.useFilter')
          : undefined,"show-footer":_setup.currentArticleImportPrepareModalStep === 'prepare'},on:{"toggle-modal":function($event){_setup.showArticleImportPrepareModal = false}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_setup.currentArticleImportPrepareModalStep === 'prepare')?_c(_setup.FormHelper,{attrs:{"config":_setup.formConfig,"form":_setup.form},model:{value:(_setup.specificArticleImportModel),callback:function ($$v) {_setup.specificArticleImportModel=$$v},expression:"specificArticleImportModel"}}):(_setup.currentArticleImportPrepareModalStep === 'import')?_c(_setup.ProductSelectionRunCFLConfirmation):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-primary mr-2",on:{"click":_setup.importSpecificArticle}},[_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.next"))+" ")])]},proxy:true}],null,false,2054612222)}),_c(_setup.Modal,{attrs:{"id":"status-modal","width":"600","show-modal":_setup.showArticleImportModal,"show-footer":false},on:{"toggle-modal":function($event){_setup.showArticleImportModal = false}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_setup.ProductSelectionRunCFLConfirmation)]},proxy:true}],null,false,2199578277)})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }