<script setup>
import { computed, defineEmits, defineProps, ref } from "vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import i18n from "@/core/plugins/vue-i18n";
import Modal from "@/components/Tools/Modal.vue";
import { labelData } from "@/components/ExternalApps/SalesChannelManagementApp/utility/labelData";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";

const props = defineProps({
  showInitialWorkflowDialog: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(["accept", "close"]);

const { selectedFeature } = useFeatures();
const showDialog = computed({
  // getter
  get() {
    return props.showInitialWorkflowDialog;
  },
  // setter
  set(newValue) {
    if (newValue) {
      return;
    }
    emits("close");
  }
});

const form = [
  {
    name: "limit",
    type: "select",
    label: i18n.t("salesChannelManagementApp.initialFile.limit"),
    options: [
      100,
      500,
      1000,
      5000,
      i18n.t("salesChannelManagementApp.initialFile.all")
    ]
  }
];

const formConfig = {
  enableVariables: false,
  snippedPrefix: "salesChannelManagementApp",
  labelColClass: "col-1",
  fieldColClass: "col-11",
  ...labelData.value
};

const formValues = ref({
  limit: i18n.t("salesChannelManagementApp.initialFile.all")
});

function accept() {
  emits("accept", formValues);
}
</script>

<template>
  <Modal
    :show-modal="showDialog"
    :title="$t('salesChannelManagementApp.initialFile.dialogTitle').toString()"
    @toggle-modal="showDialog = $event"
  >
    <template #content>
      <div class="mb-3">
        {{
          $t(
            "salesChannelManagementApp.initialFile." +
              selectedFeature +
              "DialogInfo"
          )
        }}
      </div>
      <FormHelper v-model="formValues" :config="formConfig" :form="form" />
    </template>
    <template #footer>
      <button class="btn btn-primary w-50 ml-2" @click="accept">
        {{ $t("salesChannelManagementApp.initialFile.generateInitialFile") }}
      </button>
    </template>
  </Modal>
</template>

<style scoped lang="scss"></style>
