import { ref } from "vue";
import { useAssignmentTables } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAssignmentTables";
import { useConditions } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useConditions";

export const useSaveFormatter = () => {
  const { saveAssignmentTable } = useAssignmentTables();
  const { saveConditions } = useConditions();
  const saveFormatters = ref({ saveAssignmentTable, saveConditions });

  async function saveFormatter(saveFormatter, field, value, fieldPath = "") {
    return await saveFormatters.value[saveFormatter](field, value, fieldPath);
  }

  return { saveFormatter };
};
