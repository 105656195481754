<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  currentStatus: {
    type: String,
    default: ""
  },
  title: {
    type: String,
    default: ""
  },
  info: {
    type: String,
    default: ""
  }
});

const status = computed(() => {
  let percentage = getPercentage(props.currentStatus);
  return percentage === 0 ? 1 : percentage;
});

function getPercentage(inputString) {
  if (!inputString) return 0;
  // Split the input string by '/'
  let parts = inputString.split("/");

  // Parse the parts into numbers
  let numerator = parseFloat(parts[0].trim());
  let denominator = parseFloat(parts[1].trim());

  if (denominator === 0) {
    return 0;
  }
  // Calculate the percentage
  return (numerator / denominator) * 100;
}
</script>

<template>
  <div class="text-center">
    <div class="font-weight-bold mb-2 mt-4">
      {{ title }}
    </div>
    <div class="mb-2">
      <div class="progress-bar">
        <span :style="{ right: 100 - status + '%' }" class="bar"></span>
      </div>
    </div>
    <span class="font-weight-bold">
      {{ currentStatus }}
      {{ $t("salesChannelManagementApp.product.articleExported") }}
    </span>

    <div class="mt-4">
      {{ info }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.progress-bar {
  width: 100%;
  height: 8px;
  border-radius: 12px;
  background-color: #eaecf0;
  position: relative;
  transition: all 0.3s ease-out;

  .bar {
    transition: right 0.3s ease-out;
    position: absolute;
    left: 0;
    top: 0;
    right: -1;
    height: 8px;
    background-color: $color-connect-primary;
  }
}

:deep(.v-progress-linear) {
  &.animate {
    .v-progress-linear__determinate {
      animation: move 2.5s linear infinite;
    }
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100px -100px;
  }
}
</style>
