<script setup>
import { defineEmits, defineProps } from "vue";

defineProps({
  actions: {
    type: Array,
    required: true
  },
  data: {
    type: [Object, Array],
    required: true
  }
});

const emit = defineEmits(["action"]);

function filterActions(actions, data) {
  if (actions.length === 0) {
    return [];
  }

  return actions.filter(action => {
    return !action.condition || checkCondition(action.condition, data.item);
  });
}
function checkCondition(condition, item) {
  const operators = {
    "===": (a, b) => a === b,
    "!==": (a, b) => a !== b,
    "<": (a, b) => a < b,
    ">": (a, b) => a > b,
    "<=": (a, b) => a <= b,
    ">=": (a, b) => a >= b,
    includes: (a, b) => b.includes(a)
  };

  const { operator, field, value } = condition;
  const comparisonFunction = operators[operator];

  if (!comparisonFunction) {
    throw new Error(`Unsupported operator: ${operator}`);
  }

  // if field is a path, like "meta.processIterationId" we have to split the field
  const fieldParts = field.split(".");
  let fieldValue = item;
  do {
    const part = fieldParts.splice(0, 1);
    fieldValue = fieldValue[part];
  } while (fieldValue !== undefined && fieldParts.length > 0);

  return comparisonFunction(fieldValue, value);
}

function emitAction(action, data) {
  emit("action", {
    emit: action,
    data: data
  });
}
</script>

<template>
  <div>
    <span v-for="action in filterActions(actions, data)" :key="action.key">
      <button
        v-if="!action.children"
        v-b-popover.hover.bottom="
          action.type === 'toggle'
            ? action.tooltips[data.item[action.field]]
            : action.tooltip
        "
        class="btn btn-icon btn-sm mr-1"
        @click.left="emitAction(action.emit, data)"
        @click.middle="emitAction(action.emit, data)"
      >
        <span v-if="action.label">{{ action.label }}</span>
        <i
          v-if="action.type === 'toggle'"
          :class="action.icons[data.item[action.field]]"
        />
        <i v-else-if="action.icon" :class="action.icon" />
      </button>

      <v-menu v-else-if="action.children" left offset-y>
        <template v-if="action.icon" #activator="{ on, attrs }">
          <button class="btn btn-link btn-sm btn-icon" v-bind="attrs" v-on="on">
            <i :class="action.icon" />
            <span v-if="action.label">{{ action.label }}</span>
          </button>
        </template>
        <perfect-scrollbar
          :options="{ wheelPropagation: false }"
          class="scroll h-100 max-height"
        >
          <ul class="navi navi-hover pa-0">
            <li
              v-for="child in filterActions(action.children, data)"
              :key="action.key + '-' + child.key"
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="navi-item"
            >
              <a
                class="navi-link"
                @click.left="emitAction(child.emit, data)"
                @click.middle="emitAction(child.emit, data)"
              >
                <span class="navi-text">
                  <i v-if="child.icon" :class="child.icon" />
                  <span v-if="child.label">{{ child.label }}</span>
                </span>
              </a>
            </li>
          </ul>
        </perfect-scrollbar>
      </v-menu>
    </span>
  </div>
</template>

<style scoped lang="scss">
.v-menu__content {
  background-color: #fff;
}

.max-height {
  max-height: 240px;
}
</style>
