import { useStore } from "@/core/services/store";
import i18n from "@/core/plugins/vue-i18n";
import { computed } from "vue";

const store = useStore();

export const dynamicChannelTypeTranslations = computed(() => ({
  channelType: store.getters.selectedApp?.isMarketplace
    ? i18n.t("salesChannelManagementApp.marketPlace")
    : i18n.t("salesChannelManagementApp.shop"),
  channelTypePlural: store.getters.selectedApp?.isMarketplace
    ? i18n.t("salesChannelManagementApp.marketPlacePlural")
    : i18n.t("salesChannelManagementApp.shopPlural")
}));

export const labelData = computed(() => ({
  labelData: {
    ...dynamicChannelTypeTranslations.value,
    appName: store.getters.selectedApp.label
  }
}));
