import { useStore } from "@/core/services/store";
import { computed } from "vue";
import { SET_ROUTE_PARAMS_BY_KEY } from "@/core/services/store/route.module";
import { useApplyFilter } from "@/composables/TableHelper/useApplyFilter";

const store = useStore();
const paramNames = ["page", "perPage", "searchTerm"];

export const useParams = prefix => {
  const params = computed(() => store.getters["route/params"](prefix.value));
  const page = computed({
    get: () => params?.value?.page ?? 1,
    set: value => setRouteParamsByKey("page", value)
  });
  const perPage = computed({
    get: () => params?.value?.perPage ?? 15,
    set: value => setRouteParamsByKey("perPage", value)
  });
  const searchTerm = computed({
    get: () => params?.value?.searchTerm ?? "",
    set: value => setRouteParamsByKey("searchTerm", value)
  });
  const filter = computed({
    get: () => params?.value?.filter ?? [],
    set: value => setRouteParamsByKey("filter", value)
  });
  const order = computed({
    get: () => params?.value?.order ?? [],
    set: value => setOrderBy(value)
  });
  const routeParams = computed(() => {
    const computedPrefix =
      !!prefix.value && prefix.value !== "default" ? prefix.value + "_" : "";
    let computedParams = {
      [computedPrefix + "page"]: page.value,
      [computedPrefix + "perPage"]: perPage.value
    };

    if (searchTerm.value) {
      computedParams[computedPrefix + "searchterm"] = searchTerm.value;
    }

    const { filter: computedFilter } = useApplyFilter(filter);

    for (const [key, value] of Object.entries(computedFilter.value || {})) {
      computedParams[computedPrefix + key] = value;
    }

    if (order.value) {
      params.value[computedPrefix.value + order.value.field] =
        order.value.direction;
    }

    return computedParams;
  });

  const paramNamesPrefixed = computed(() => {
    if (!prefix) {
      return paramNames;
    }
    return paramNames.map(name => prefix + "_" + name);
  });

  function setOrderBy(value) {
    const { field, direction } = value;

    const computedOrder =
      field && direction ? { ["orderBy[" + field + "]"]: direction } : {};
    const firstKey = Object.keys(computedOrder).at(0);

    setRouteParamsByKey(firstKey, computedOrder[firstKey]);
  }

  /**
   * @param key
   * @param value
   */
  function setRouteParamsByKey(key, value) {
    store.dispatch("route/" + SET_ROUTE_PARAMS_BY_KEY, {
      key: key,
      prefix: prefix?.value ?? "default",
      value: value
    });
  }

  return {
    params,
    page,
    perPage,
    searchTerm,
    filter,
    order,
    paramNamesPrefixed,
    routeParams
  };
};
